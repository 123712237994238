import { css } from 'styled-components'

import { TooltipVariantProps } from '../types'

export const TOOLTIP_VARIANTS: TooltipVariantProps = {
  up: {
    top: 'unset',
    right: 'unset',
    minWidth: '300px',
    maxHeight: '142px',
    minHeight: 'unset',
    maxWidth: 'unset',
    ':after': { bottom: '-16px', transform: 'none' }
  },
  down: {
    bottom: 'unset',
    right: 'unset',
    minWidth: '300px',
    maxHeight: '142px',
    minHeight: 'unset',
    maxWidth: 'unset',
    ':after': { top: '-16px', transform: 'rotate(180deg)' }
  },
  left: {
    bottom: 'unset',
    left: 'unset',
    minHeight: '300px',
    maxWidth: '142px',
    minWidth: 'unset',
    maxHeight: 'unset',
    ':after': { right: '-16px', transform: 'rotate(270deg)' }
  },
  right: {
    bottom: 'unset',
    right: 'unset',
    minHeight: '300px',
    maxWidth: '142px',
    minWidth: 'unset',
    maxHeight: 'unset',
    ':after': { left: '-16px', transform: 'rotate(90deg)' }
  }
}

const leftCSS = (offset: number, additionalOffset: number): any => css`
  left: -${offset}px;
  :after {
    left: ${offset + additionalOffset}px;
  }
`
const topCSS = (offset: number, additionalOffset: number): any => css`
  top: -${offset}px;
  :after {
    top: ${offset + additionalOffset}px;
  }
`
export const offsetStyles = {
  up: leftCSS,
  down: leftCSS,
  left: topCSS,
  right: topCSS
}

export const distanceStyles = {
  up: (distance: number) =>
    css`
      bottom: calc(100% + ${distance}px);
    `,
  down: (distance: number) =>
    css`
      top: calc(100% + ${distance}px);
    `,
  left: (distance: number) =>
    css`
      right: calc(100% + ${distance}px);
    `,
  right: (distance: number) =>
    css`
      left: calc(100% + ${distance}px);
    `
}
