import { BackgroundImage } from '@dtx-company/shared-components/src/foundation/Icons/Product/BackgroundImage'
import { BackgroundMenu } from './BackgroundMenu/BackgroundMenu'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { CarouselContext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { ColorMenu } from './ColorMenu/ColorMenu'
import { DesignTabs } from './DesignMenu.types'
import { FC, useContext, useEffect, useState } from 'react'
import { FontMenu } from '../FontMenu/FontMenu'
import { FontStyle } from '@dtx-company/shared-components/src/foundation/Icons/Product/FontStyle'
import { LinkShapes } from '@dtx-company/shared-components/src/foundation/Icons/Product/LinkShapes'
import { LinksMenu } from './LinksMenu/LinksMenu'
import { MenuItem } from '../NewMenuContent/MenuItem'
import { PaintPalette } from '@dtx-company/shared-components/src/foundation/Icons/Product/PaintPalette'
import { RemoveBrandingMenu } from './RemoveBrandingMenu/RemoveBrandingMenu'
import { ResetPageTheme } from '../../../Customize/ResetPageTheme'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { theme } from '@dtx-company/shared-components/src'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useDesignMenuState } from './DesignMenu.hooks'
import { useShowResetCustomizationOptions } from '../../../../FlowpageTemplates/hooks/useShowResetCustomizationOptions'
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined'
import styled from 'styled-components'

const { flowBlue, black } = theme.colors.primary
const MenuContentWrapper = styled.div<{ maxHeight?: string }>`
  max-height: ${({ maxHeight }) => maxHeight};
  padding-bottom: 16px;
  overflow: scroll;
`

const StyledCarouselWrapper = styled.div`
  width: 100%;

  & > .carousel {
    width: 100%;
  }
`

const TABS_LIST = [
  DesignTabs.COLOR,
  DesignTabs.LINKS,
  DesignTabs.BACKGROUND,
  DesignTabs.TEXT,
  DesignTabs.BRANDING
]

const DesignMenuTabs: FC = () => {
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide)

  // Subscribe to changes to the current slide index
  useEffect(() => {
    const onChange = (): void => {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  return (
    <Box justifyContent={'space-around'} alignItems={'flex-end'} width={'100%'}>
      <Spacer />
      <MenuItem
        Icon={
          <PaintPalette
            strokeWidth="3px"
            fill={TABS_LIST[currentSlide] === DesignTabs.COLOR ? flowBlue : black}
          />
        }
        onClick={() => carouselContext.setStoreState({ currentSlide: 0 })}
        label="Color"
        selected={TABS_LIST[currentSlide] === DesignTabs.COLOR}
      />
      <Spacer />
      <MenuItem
        Icon={
          <LinkShapes
            strokeWidth="3px"
            fill={TABS_LIST[currentSlide] === DesignTabs.LINKS ? flowBlue : black}
          />
        }
        onClick={() => carouselContext.setStoreState({ currentSlide: 1 })}
        label="Links"
        selected={TABS_LIST[currentSlide] === DesignTabs.LINKS}
      />
      <Spacer />
      <MenuItem
        Icon={
          <BackgroundImage
            strokeWidth="3px"
            fill={TABS_LIST[currentSlide] === DesignTabs.BACKGROUND ? flowBlue : black}
          />
        }
        onClick={() => carouselContext.setStoreState({ currentSlide: 2 })}
        label="Background"
        selected={TABS_LIST[currentSlide] === DesignTabs.BACKGROUND}
      />
      <Spacer />
      <MenuItem
        Icon={
          <FontStyle
            strokeWidth="3px"
            fill={TABS_LIST[currentSlide] === DesignTabs.TEXT ? flowBlue : black}
          />
        }
        onClick={() => carouselContext.setStoreState({ currentSlide: 3 })}
        label="Text"
        selected={TABS_LIST[currentSlide] === DesignTabs.TEXT}
      />
      <Spacer />
      <MenuItem
        Icon={
          <DesignServicesOutlinedIcon
            fontSize="large"
            htmlColor={TABS_LIST[currentSlide] === DesignTabs.BRANDING ? flowBlue : black}
          />
        }
        onClick={() => carouselContext.setStoreState({ currentSlide: 4 })}
        label="Settings"
        selected={TABS_LIST[currentSlide] === DesignTabs.BRANDING}
      />
      <Spacer />
    </Box>
  )
}

export const DesignMenu: FC<{ height?: string }> = ({ height }) => {
  const { pageTheme } = useDesignMenuState()
  const page = useCurrentPage()
  const showResetCustomizeOptions = useShowResetCustomizationOptions({ page })
  const menuMaxHeight = `calc(${height || '100%'} - 110px)`

  return (
    <StyledCarouselWrapper>
      <CarouselProvider
        naturalSlideHeight={400}
        naturalSlideWidth={300}
        totalSlides={5}
        isIntrinsicHeight
      >
        <Box alignItems="center" padding="24px 16px 0px 16px" width="100%" flexDirection="column">
          <DesignMenuTabs />
          <Box width={'100%'} position="relative">
            <Slider>
              <Slide index={0}>
                <MenuContentWrapper maxHeight={menuMaxHeight}>
                  <ColorMenu pageTheme={pageTheme} />
                </MenuContentWrapper>
              </Slide>
              <Slide index={1}>
                <MenuContentWrapper maxHeight={menuMaxHeight}>
                  <LinksMenu pageTheme={pageTheme} />
                </MenuContentWrapper>
              </Slide>
              <Slide index={2}>
                <MenuContentWrapper maxHeight={menuMaxHeight}>
                  <BackgroundMenu pageTheme={pageTheme} />
                </MenuContentWrapper>
              </Slide>
              <Slide index={3}>
                <MenuContentWrapper maxHeight={menuMaxHeight}>
                  <FontMenu pageTheme={pageTheme} />
                </MenuContentWrapper>
              </Slide>
              <Slide index={4}>
                <MenuContentWrapper maxHeight={menuMaxHeight}>
                  <RemoveBrandingMenu />
                  {showResetCustomizeOptions && <ResetPageTheme themeId={pageTheme?.id || ''} />}
                </MenuContentWrapper>
              </Slide>
            </Slider>
          </Box>
        </Box>
      </CarouselProvider>
    </StyledCarouselWrapper>
  )
}
