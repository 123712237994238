import { AddWidgetType } from '..'
import { FC, FormEvent, ReactElement, ReactNode } from 'react'
import { LinkMetaType, SocialLinkType } from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { stopPropagationEvents } from '../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { useIsMobileOrTablet } from '@app/code/src/hooks/useIsMobileOrTablet'
import styled, { css } from 'styled-components'

export interface FormProps {
  widgetObj: AddWidgetType | SocialLinkType
  order: number
  curr?: LinkType
  isUsernameLink?: boolean
  handleClose: () => void
  defaultFields?: {
    imageUrl?: string
    link?: string
    title?: string
    meta?: LinkMetaType
  }
}

const FormContentWrapper = styled.div<{ disableAutoSpace: boolean }>`
  margin-top: 12px;
  ${({ disableAutoSpace }) => {
    if (!disableAutoSpace) {
      return css`
        & > div {
          margin-bottom: 18px;
        }
      `
    }
    return css``
  }}
`

const StyledFormWrapper2 = styled.form<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  @media (min-width: 1024px) {
    background-color: transparent;
    border-radius: 12px;
    padding: 0px;
  }
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding} !important;
    `}
`
interface FormPropsWrapper {
  children: ReactNode
  padding?: string
  title: ReactElement
  noPreview?: boolean
  disableAutoSpace?: boolean
  subheader?: ReactNode
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
}

export const StyledForm: FC<FormPropsWrapper> = ({
  children,
  onSubmit,
  disableAutoSpace = false,
  noPreview = false,
  title,
  subheader = null,
  padding
}: FormPropsWrapper) => {
  const mobile = useIsMobileOrTablet()
  return (
    <StyledFormWrapper2 padding={padding} onSubmit={onSubmit}>
      {title}
      {subheader}
      <FormContentWrapper disableAutoSpace={disableAutoSpace}>{children}</FormContentWrapper>
    </StyledFormWrapper2>
  )
}

export const StyledFormV2: FC<{
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  children: ReactNode
  padding?: string
}> = ({ children, onSubmit, padding }) => {
  return (
    <StyledFormWrapper2 padding={padding} onSubmit={onSubmit} {...stopPropagationEvents}>
      {children}
    </StyledFormWrapper2>
  )
}
