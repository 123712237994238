import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps, theme } from '@dtx-company/shared-components/src'
import { CenterBox } from '@dtx-company/shared-components/src/components/atoms/Box'
import { FC, ReactNode } from 'react'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input'
import { LockToggle } from '../../../../../../ToggleTemplateLock/ToggleTemplateLock'
import { LockedTemplateField } from '../../../../../../../FlowpageTemplates/LockedTemplateField/LockedTemplateField'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer'
import { Verified } from '../../../../../../../../components/Verified/Verified'
import { getLockedFieldFromTemplateConfig } from '../../../../../../../FlowpageTemplates/utils/getLockedFieldFromTemplateConfig'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useBioEditorState } from './BioEditor.hooks'
import { useCurrentPagePermissions } from '../../../../../../../../utils/useGetCurrentPagePermissions'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'
import { useShowTemplateLocks } from '@app/common/src/hooks/useShowTemplateLocks'
import ChangeAvatar from '../../../../../../../../components/profile/ChangeAvatar'
import styled from 'styled-components'

export const Wrapper: FC<
  BoxProps & {
    viewOnly?: boolean
    LockComponent: ReactNode
  }
> = ({ children, LockComponent, viewOnly, ...rest }) => {
  return (
    <CenterBox>
      {LockComponent}
      <WrapperContainerBox
        borderRadius={{ _: '0px', md: '12px' }}
        padding={{ _: '0px', md: '16px' }}
        width="100%"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        backgroundColor={{ _: 'unset', md: 'white' }}
        border={{ _: 'unset', md: `1px solid ${theme.colors.secondary.border}` }}
        viewOnly={viewOnly}
        {...rest}
      >
        {children}
      </WrapperContainerBox>
    </CenterBox>
  )
}

export const FLOWPAGE_WIDGET_HOVER_BOX_SHADOW =
  '0px 1px 2px rgba(17, 17, 17, 0.3), 0px 2px 6px rgba(17, 17, 17, 0.15)'
const WrapperContainerBox = styled(Box)<{ viewOnly?: boolean }>`
  box-shadow: none;
  &:hover,
  &:active {
    box-shadow: ${({ viewOnly }) => (viewOnly ? 'none' : FLOWPAGE_WIDGET_HOVER_BOX_SHADOW)};
  }
`

export const BioEditor: FC<{ page?: PageType | null; onMobile?: boolean }> = ({
  page,
  onMobile
}) => {
  const {
    setDisplayName,
    onDisplayNameBlur,
    setCaption,
    onCaptionChange,
    toggleFieldLock,
    titleLocked,
    descriptionLocked,
    profileImageLocked,
    displayName,
    caption
  } = useBioEditorState({
    page
  })
  const { isViewer } = useAuthState()
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const black = theme.colors.primary.black
  const fontColor = page?.theme?.fontColor || 'flowpage.contrastText'
  const titleAndDescriptionColor = onMobile ? page?.theme?.titleColor || fontColor : black
  const { showTemplateLocks } = useShowTemplateLocks()
  const { isTemplateEditor } = usePageTemplateEditorState()
  const isTitleFieldLocked =
    !isTemplateEditor &&
    getLockedFieldFromTemplateConfig({
      templateConfig: page?.templateConfig,
      field: 'title'
    })
  const isDescriptionFieldLocked =
    !isTemplateEditor &&
    getLockedFieldFromTemplateConfig({
      templateConfig: page?.templateConfig,
      field: 'description'
    })

  return (
    <>
      <Wrapper
        position="relative"
        viewOnly={hasViewOnlyAccess}
        LockComponent={
          showTemplateLocks && (
            <LockToggle
              disabled={hasViewOnlyAccess}
              locked={profileImageLocked}
              toggleLocked={async () => await toggleFieldLock('profileImage')}
            />
          )
        }
      >
        {page && <ChangeAvatar page={page} />}
      </Wrapper>
      <Spacer mb="8px" />
      <Wrapper
        viewOnly={hasViewOnlyAccess}
        padding={
          isTitleFieldLocked ? { _: '0px', md: '8px 8px 8px 4px' } : { _: '0px', md: '16px' }
        }
        LockComponent={
          showTemplateLocks && (
            <LockToggle
              disabled={hasViewOnlyAccess}
              locked={titleLocked}
              toggleLocked={async () => await toggleFieldLock('title')}
            />
          )
        }
      >
        {isTitleFieldLocked && <LockedTemplateField />}
        <Input
          type="text"
          defaultValue={page?.displayName || ''}
          placeholder="Enter your name"
          height="45px"
          fontSize="24px"
          opacity={isTitleFieldLocked ? 0.35 : 1}
          maxWidth="unset"
          inputProps={{
            name: 'displayName',
            textAlign: 'center',
            color: titleAndDescriptionColor,
            fontWeight: 600,
            focusColor: titleAndDescriptionColor,
            padding: '8px',
            width: '100%',
            value: displayName
          }}
          backgroundColor="transparent"
          id="bio-displayname-input"
          onChange={e => setDisplayName(e.target.value)}
          onBlur={onDisplayNameBlur}
          endAdornment={<Verified verified={page?.verified} />}
          disabled={isViewer || hasViewOnlyAccess || isTitleFieldLocked}
        />
      </Wrapper>
      <Spacer mb="8px" />

      <Wrapper
        padding={
          isDescriptionFieldLocked ? { _: '0px', md: '8px 8px 8px 4px' } : { _: '0px', md: '16px' }
        }
        viewOnly={hasViewOnlyAccess}
        LockComponent={
          showTemplateLocks && (
            <LockToggle
              disabled={hasViewOnlyAccess}
              locked={descriptionLocked}
              toggleLocked={async () => await toggleFieldLock('description')}
            />
          )
        }
      >
        {isDescriptionFieldLocked && <LockedTemplateField />}
        <Input
          type="text"
          multiline
          enableResize={true}
          resizeDirection="vertical"
          maxWidth="unset"
          height="auto"
          opacity={isTitleFieldLocked ? 0.35 : 1}
          minHeight="35px"
          id="bio-description-input"
          defaultValue={page?.caption || ''}
          placeholder="Enter a description or bio"
          inputProps={{
            name: 'caption',
            textAlign: 'center',
            color: titleAndDescriptionColor,
            focusColor: titleAndDescriptionColor,
            padding: '8px',
            value: caption
          }}
          onChange={e => setCaption(e.target.value)}
          onBlur={onCaptionChange}
          disabled={isViewer || hasViewOnlyAccess || isDescriptionFieldLocked}
          backgroundColor="transparent"
        />
      </Wrapper>
    </>
  )
}
