import { ActivateButton } from './ActivateButton'
import { BorderProps } from 'styled-system'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps, ImageStyleProps, theme } from '@dtx-company/shared-components/src'
import { DeleteModeDrawer } from './DeleteModeDrawer'
import { FC, ReactNode } from 'react'
import { FLOWPAGE_WIDGET_HOVER_BOX_SHADOW } from '../../../../components/profile/PageEditor/components/PagePreview/components/MobilePagePreview/BioEditor/BioEditor'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { LinkEditSection } from '../../../../components/profile/Links/LinkEditSection'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { LockedTemplateField } from '../../../FlowpageTemplates/LockedTemplateField/LockedTemplateField'
import { ManagerSettings } from './ManagerSettings'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { indexInterfaceWithVar } from '@dtx-company/true-common/src/utils/objects'
import { useManagerSettings } from './useManagerSettings'
import { useScreenSizeBreakpoints } from '@app/common/src/hooks/useScreenSizeBreakpoints'
import styled from 'styled-components'

interface ManageCardProps extends BoxProps {
  inactive?: boolean
  empty?: boolean
  key?: string
}

export const InlineManagerCard: FC<
  ManageCardProps & { showEditLink?: boolean; padding?: string; viewOnly?: boolean }
> = ({
  key,
  children,
  inactive = false,
  showEditLink,
  padding = showEditLink ? '0' : '16px',
  viewOnly,
  ...rest
}) => {
  return (
    <InlineManagerCardContainer
      inactive={inactive}
      showEditLink={showEditLink}
      padding={padding}
      key={key}
      bg="primary.white"
      viewOnly={viewOnly}
      {...rest}
    >
      {children}
    </InlineManagerCardContainer>
  )
}

const InlineManagerCardContainer = styled(Box)<{
  inactive: boolean
  showEditLink: boolean | undefined
  padding?: string
  viewOnly?: boolean
}>`
  opacity: ${({ inactive }) => (inactive ? '0.4' : '1')};
  align-items: center;
  margin: 8px 0px;
  cursor: pointer;
  max-width: 600px;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  border: ${({ showEditLink }) =>
    showEditLink ? 'none' : `1px solid ${theme.colors.secondary.border}`};
  padding: ${({ padding }) => padding};
  &:hover,
  &:active {
    box-shadow: ${({ showEditLink, viewOnly }) =>
      showEditLink || viewOnly ? 'float' : FLOWPAGE_WIDGET_HOVER_BOX_SHADOW};
  }
`

export interface ManagerProps extends BorderProps {
  link: LinkType
  key?: string
  empty?: boolean
  hideIcon?: boolean
  dataCy?: string | null
  editWidget: () => void
  iconProps?: Partial<ImageStyleProps>
  handle?: ReactNode
  iconUrl?: string
  altTitle?: string
  customTitleSection?: JSX.Element
  children?: ReactNode
}

export const AnimatedImage = styled(Image)<ImageStyleProps & { isOpen: boolean }>`
  transform: ${props => (props.isOpen ? `rotate(180deg)` : 'rotate(0deg)')};
  transition: transform 0.25s;
`
const DefaultThumbnail = styled(Image)`
  img {
    object-fit: cover;
  }
`
const FeaturedThumbnail = styled(Image)`
  img {
    object-fit: contain;
  }
`

export const DefaultManager: FC<ManagerProps> = ({
  hideIcon,
  link,
  altTitle,
  key,
  editWidget,
  iconProps,
  dataCy = null,
  empty = false,
  handle,
  children,
  iconUrl,
  customTitleSection,
  ...rest
}) => {
  const {
    hasViewOnlyAccess,
    onDeleteLink,
    deleteLinkModeActive,
    toggleDeleteMode,
    isActive,
    toggleActivate,
    managerContainerStyles,
    showEditLink,
    setShowEditLink,
    toggleEditMode,
    childOnClick,
    isTemplateConfigLocked
  } = useManagerSettings(link, editWidget, empty)

  const { isMobile } = useScreenSizeBreakpoints()

  const { description, thumbNailImgUrl, title, embed } = link
  const url = indexInterfaceWithVar(link.actionData, 'link')
  const hasNonDefaultImage = thumbNailImgUrl && thumbNailImgUrl !== '/icons/social-media/blank.png'
  const rightMargin = hasViewOnlyAccess ? '52px' : '12px'
  return (
    <InlineManagerCard
      showEditLink={showEditLink}
      padding={'0'}
      viewOnly={hasViewOnlyAccess || isTemplateConfigLocked}
      {...managerContainerStyles.deactivatedContainerStyles}
    >
      {/* Prevent a duplicate form from rendering inline (below the preview) on mobile,
      in addition to the form in drawer that comes up from the bottom of the screen */}
      {showEditLink && !isMobile ? (
        <>
          <Box display="none">{handle}</Box>
          <LinkEditSection handleClose={() => setShowEditLink(false)} />
        </>
      ) : (
        <Box flexDirection="column" width="100%" justifyContent="space-between" alignItems="center">
          <Box width="100%" justifyContent="space-between" minHeight="96px" alignItems="center">
            {!isTemplateConfigLocked && (
              <ManagerSettings
                onClickEdit={toggleEditMode}
                onClickDelete={toggleDeleteMode}
                disabled={hasViewOnlyAccess}
                deleteModeActive={deleteLinkModeActive}
                viewOnly={hasViewOnlyAccess}
              />
            )}
            {isTemplateConfigLocked && <LockedTemplateField />}
            {(hasNonDefaultImage || iconUrl) && (
              <>
                {link.displayType === 'featured' ? (
                  <FeaturedThumbnail
                    flexShrink={0}
                    width="60px"
                    height="60px"
                    borderRadius="4px"
                    {...iconProps}
                    src={hasNonDefaultImage ? thumbNailImgUrl : iconUrl || ''}
                    opacity={managerContainerStyles.iconOpacity}
                  />
                ) : (
                  <DefaultThumbnail
                    flexShrink={0}
                    width="60px"
                    height="60px"
                    borderRadius={link.actionData?.widgetDisplayType === 'circle' ? '50%' : '4px'}
                    {...iconProps}
                    src={hasNonDefaultImage ? thumbNailImgUrl : iconUrl || ''}
                    opacity={managerContainerStyles.iconOpacity}
                  />
                )}
              </>
            )}
            <Box
              overflow="auto"
              flexDirection="column"
              ml="16px"
              flex="1 1"
              onClick={childOnClick}
              py="16px"
            >
              {customTitleSection ? (
                customTitleSection
              ) : (
                <Box flexDirection="column" maxWidth="350px" overflowX="hidden">
                  <Text
                    variant="body/medium"
                    fontWeight="600"
                    opacity={managerContainerStyles.textOpacity}
                    color={managerContainerStyles.titleColor}
                  >
                    {embed && url ? url : title || altTitle}
                  </Text>
                  <Text
                    opacity={managerContainerStyles.textOpacity}
                    color={managerContainerStyles.titleColor}
                    variant="body/small"
                  >
                    {description}
                  </Text>
                </Box>
              )}
            </Box>
            <>
              <ActivateButton
                viewOnly={hasViewOnlyAccess || isTemplateConfigLocked}
                toggleActivated={toggleActivate}
                activated={isActive}
              />
              <Spacer mr="12px" />
              {!hasViewOnlyAccess && !isTemplateConfigLocked && handle}
              <Spacer mr={rightMargin} />
            </>
          </Box>
          {children}
        </Box>
      )}
      {!showEditLink && (
        <>
          {deleteLinkModeActive && <Spacer mb="5px" />}
          <DeleteModeDrawer
            deleteModeActive={deleteLinkModeActive}
            toggleDeleteMode={toggleDeleteMode}
            onDelete={onDeleteLink}
          />
        </>
      )}
    </InlineManagerCard>
  )
}

export const NoLinks: FC<{
  editWidget: () => void
}> = ({ editWidget }: { editWidget: () => void }) => {
  const emptyLink: Link = {
    description: '',
    id: 'empty',
    __typename: 'Link',
    actionData: {},
    type: 'link',
    active: true,
    order: 0,
    provider: LinkProvider.LINK,
    embed: false,
    title: 'Add your first link',
    thumbNailImgUrl: '/icons/social-media/link.svg',
    displayType: 'default'
  }

  return (
    <Box display={['none', 'none', 'flex']} padding="0px 20px">
      <DefaultManager
        hideIcon
        key={'default-empty-manager'}
        editWidget={editWidget}
        empty={true}
        link={emptyLink}
      />
    </Box>
  )
}
