import { FC } from 'react'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { theme } from '@dtx-company/shared-components/src'
import styled from 'styled-components'

export const ManagerCancelButton: FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <StyledCancelButtonContainer onClick={onClick} data-testid="manager-cancel-button">
      <Text>Cancel</Text>
    </StyledCancelButtonContainer>
  )
}

const StyledCancelButtonContainer = styled.button`
  border: none;
  cursor: pointer;
  position: absolute;
  text-align: right;
  z-index: 12px;
  top: 20px;
  right: 16px;
  background-color: transparent;
  &:hover {
    p {
      color: #033ddd80;
    }
  }
  p {
    color: ${theme.colors.primary.flowBlue};
    position: relative;
    font-weight: 600;
    font-size: 16px;
  }
`
