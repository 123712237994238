import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  MarginProps,
  SpaceProps,
  border,
  color,
  flexbox,
  layout,
  margin,
  space
} from 'styled-system'
import { theme } from '../../../theme'
import styled, { css } from 'styled-components'

export type SelectContainerProps = LayoutProps &
  FlexboxProps &
  BorderProps &
  SpaceProps &
  ColorProps &
  MarginProps &
  React.HTMLProps<HTMLDivElement>

export const SelectContainer = styled.div<SelectContainerProps>`
  display: flex;
  position: relative;
  width: 200px;
  * {
    box-sizing: border-box;
  }
  ${layout}
  ${color}
  ${margin}
  ${border}
  ${space}
  ${flexbox}
`

export type SelectButtonProps = { disabled?: boolean; 'data-testid'?: string } & ColorProps &
  BorderProps &
  React.HTMLProps<HTMLDivElement>

export const SelectButton = styled.div<SelectButtonProps>`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0 17px;
  color: ${theme.colors.primary.black};
  border: 1px solid ${theme.colors.secondary.border};
  :hover {
    border: 1px solid ${theme.colors.primary.grey};
  }
  :focus {
    border: 1px solid ${theme.colors.primary.black};
  }
  ${color}
  ${flexbox}
  ${border}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
      background-color: #dadcdd;
      color: ${theme.colors.primary.grey};
      :focus,
      :hover {
        border: 1px transparent solid;
        cursor: initial;
      }
    `}
`
