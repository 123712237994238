import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { DeleteV2, EditV2, theme } from '@dtx-company/shared-components/src'
import { FC } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { stopPropagationEvents } from '../../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import styled from 'styled-components'

export const ManagerSettings: FC<{
  onClickEdit?: () => void
  onClickDelete?: () => void
  deleteModeActive?: boolean
  dataCy?: string
  disabled: boolean
  viewOnly: boolean
}> = ({ onClickEdit, onClickDelete, deleteModeActive, disabled, dataCy, viewOnly }) => {
  const deleteButtonFill = deleteModeActive ? theme.colors.primary.black : theme.colors.primary.grey
  return (
    <Box
      flexDirection="column"
      height="100%"
      opacity={disabled ? 0.5 : 1}
      {...(dataCy && { 'data-test': dataCy })}
      justifyContent="center"
      m="8px"
    >
      {viewOnly ? (
        <Box height="80px" width="40px" justifyContent="center" alignItems="center">
          <Icon
            width="24px"
            height="24px"
            src="/icons/lock-outline-grey.svg"
            alt="manager locked"
          />
        </Box>
      ) : (
        <>
          <StyledManagerButton
            selected={false}
            disabled={disabled}
            onClick={() => {
              onClickEdit?.()
              events.Flowpage_PageProfile_Clicked_Edit_Link()
            }}
            aria-label="edit link"
          >
            <EditV2
              height="24px"
              width="24px"
              fill={theme.colors.primary.grey}
              data-testid="edit-widget-button"
            />
          </StyledManagerButton>
          <StyledManagerButton
            selected={deleteModeActive ?? false}
            disabled={disabled}
            onClick={() => {
              onClickDelete?.()
              events.Flowpage_PageProfile_Clicked_Delete_Link()
            }}
            {...stopPropagationEvents}
            aria-label="delete link"
          >
            <DeleteV2
              data-testid="delete-widget-button"
              height="24px"
              width="24px"
              fill={deleteButtonFill}
            />
          </StyledManagerButton>
        </>
      )}
    </Box>
  )
}

const StyledManagerButton = styled.button<{ selected: boolean }>`
  cursor: pointer;
  border: none;
  background-color: ${({ selected }) => (selected ? theme.colors.secondary.border : 'transparent')};
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: 2px solid rgba(3, 61, 221, 0.5);
  }
  &:hover {
    background-color: ${theme.colors.secondary.border};
  }
`
